import React from 'react'
import styled from '@emotion/styled'

const InstaLoader = styled('div')`
  height: calc(40vw);
  background-color: black;
  height: ${ props => props.height && `${ props.height }vw` };
`

const Loader = props => {
  return <InstaLoader>Loading...</InstaLoader>
}

export default Loader
