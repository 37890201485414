import React, { useState, useEffect } from 'react'
import ImageVideo from '../ImageVideo'
import styled from '@emotion/styled'
import { Section, GridContainer } from '../shared/section'
import { breakpoints, colors, spacing } from '../../styles/variables'
import useIntersect from '../useIntersect'

const ImageGridSection = styled(Section)`
  padding: 0;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: 0;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: 0;
  }
`

const ImageGridBase = styled(GridContainer)`
  grid-gap: ${ spacing.xs }px;
  padding: 0;
  grid-template-rows: repeat(2, minmax(0px, 1fr));
  grid-auto-flow: column;
  max-height: 90vh;
  > div {
    &:nth-of-type(1) {
      grid-column: span 4;
      grid-row: span 2;
    }
    &:nth-of-type(2) {
      grid-column: span 4;
      grid-row: span 1;
    }
    &:nth-of-type(3) {
      grid-column: span 4;
      grid-row: span 1;
    }
    &:nth-of-type(4) {
      grid-column: span 4;
      grid-row: span 2;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-template-rows: repeat(4, minmax(0px, 1fr));
    grid-auto-flow: dense;
    grid-gap: ${ spacing.xs }px;
    > div {
      &:nth-of-type(1) {
        grid-column: span 7;
        grid-row: span 2;
      }
      &:nth-of-type(2) {
        grid-column: span 5;
        grid-row: span 1;
      }
      &:nth-of-type(3) {
        grid-column: span 5;
        grid-row: span 1;
      }
      &:nth-of-type(4) {
        grid-column: span 12;
        grid-row: span 2;
      }
    }
  }
`

const ImageGrid = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])

  const images = data.images
  const colLength = data.images.length
  let colSet = 2
  let colRemainder = 0

  if (colLength / 4 === 1) {
    colSet = 4
  }
  if (colLength / 3 === 1) {
    colSet = 3
  } else {
    colSet = 3
    colRemainder = colLength % 3
  }

  return (
    <ImageGridSection
      ref={ref}
      entry={entry}
      bgcolor={data.bgColor ? colors[data.bgColor] : colors.neutral}
      className={`fullwidth overflow-hidden ${ seen ? 'shown' : 'unshown' }`}
    >
      <ImageGridBase
        justify="center"
        align="center"
        fw={data.fullWidth && data.fullWidth.toString()}
        gap={data.gap && data.gap.toString()}
        bgcolor={data.bgColor && data.bgColor.toString()}
        cols={colSet}
        remainder={colRemainder}
      >
        {images.map(img => (
          <ImageVideo file={img} key={img.id} />
        ))}
      </ImageGridBase>
    </ImageGridSection>
  )
}

export default ImageGrid
