import React from 'react'
/* eslint-disable max-len */
const Plus = ({ className }) => {
  return (
    <svg className={`plus ${ className }`} viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5 2.76322e-06L32.5 65M-2.9664e-06 33L65 33" stroke="black" strokeWidth="2" />
    </svg>
  )
}

export default Plus
