import React from 'react'
import { H2, HR, Paragraph } from '../shared/typography'
import { Section, SectionTitle, GridContainer, GridItem } from '../shared/section'
import { colors } from '../../styles/variables'
import Service from '../../components/service'
import useIntersect from '../useIntersect'

const ServicesBlock = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })
  return (
    <Section className="fullwidth" ref={ref} entry={entry} bgcolor={colors.neutral} color={colors.text}>
      <SectionTitle vision={entry.intersectionRatio}>{data.sectionTitle}</SectionTitle>
      {data.style === 'Grid'
        ? (
          <div className="space-y-8">
            <h2 className="h2 max-w-prose">{data.sectionHeader}</h2>
            <p className="p max-w-prose">{data.servicesDescription.servicesDescription}</p>
            <div className="w-full">
              <ul className="w-full grid grid-cols-2 gap-px bg-black
              border-t border-b border-solid border-black">
                {data.services.length && data.services.map((service, index) => (
                  <li className="h5 p-8 flex items-center justify-center text-center bg-neutral">
                    {service.serviceTitle}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )
        : (
          <>
            <GridContainer>
              <GridItem span="6">
                <H2>{data.sectionHeader} {data.style}</H2>
              </GridItem>
              <GridItem span="6">
                <Paragraph className="mb mt-1">{data.servicesDescription.servicesDescription}</Paragraph>
              </GridItem>
            </GridContainer>
            <HR className="mt-10" />
            {data.services.length && data.services.map((service, index) => (
              <Service key={index} data={service} />
            ))}
          </>
        )}
    </Section>
  )
}

export default ServicesBlock
