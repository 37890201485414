import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { colors, breakpoints, fonts } from '../../styles/variables'
import { Section, SectionTitle } from '../../components/shared/section'
import useIntersect from '../useIntersect'
import LeadForm from '../leadForm'
import StartForm from '../start'
import { Paragraph, TextContainer } from '../shared/typography'

const LeadCaptureSection = styled(Section)`
    .leadCaptureIntro {
        padding: 0 1rem;
        p {
            font-weight: bold;
            letter-spacing: 0px;
            font-size: 125%;
        }
    }
`

const LeadCapture = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])

  const bgcolor = data.style === 'Feature' ? colors.purple : '#ffffff'
  return (
    <div
      style={{ backgroundColor: bgcolor }}
      className={`${ seen ? 'shown fullwidth' : 'unshown fullwidth' }`}
      ref={ref}
      entry={entry}
    >
      {data.style === 'Feature'
        ? (
          <div>
            <StartForm modal={false} introText={data.introText} />
          </div>)
        : (
          <div className="px-8 md:px-24 lg:px-32 py-8 md:py-24 lg:py-24 relative">
            <div className="absolute top-1/2 left-5 hidden md:block" aria-hidden="true">
              <div className={`relative ${ entry.intersectionRatio > 0.5 ? 'opacity-100' : 'opacity-0' }`}>
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 -rotate-90">
                  <h5 className="h5 whitespace-nowrap">Get Started Here</h5>
                </div>
              </div>
            </div>
            {(data.introText && data.introText.introText) && (
              <p className="p--bold max-w-prose px-4">
                {data.introText.introText}
              </p>
            )}
            <LeadForm/>
          </div>)}

    </div>
  )
}

export default LeadCapture
