import React from 'react'
import ImageVideo from '../ImageVideo'
import styled from '@emotion/styled'
import { grid, spacing, breakpoints } from '../../styles/variables'
import { Component } from '../shared/componentBase'
import { H3, H4, Paragraph, TextContainer } from '../shared/typography'
import { OuterSection, Section, SectionTitle } from '../shared/section'

const Hero = styled('div')`
  position: relative;
  height: 100%;
  min-height: calc(60vh);
  margin-top: 120px;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    min-height: 0px;
    margin-top: 76px;
  }
`

const HeroImage = styled('div')`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const HeroText = styled(Section)`
  z-index: 1;
  background: transparent;
  color: #FFFBF6;
`

const HeaderBG = ({ data }) => {
  return (
    <OuterSection>
      <Hero>
        <HeroImage>
          <ImageVideo file={data.heroImage} />
        </HeroImage>
        <HeroText>
          <H3>{data.title} {data.title}</H3>
        </HeroText>
      </Hero>
    </OuterSection>
  )
}

export default HeaderBG
