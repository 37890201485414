import React from 'react'
import Callout from './flexContent/callout'
import MissionPlan from './flexContent/missionPlan'
import MultiColWithText from './flexContent/multiColWithText'
import ImageGrid from './flexContent/imageGrid'
import Instagram from './flexContent/instagram'
import RichText from './flexContent/richText'
import HeaderBG from './flexContent/headerBG'
import ImageBlock from './flexContent/imageBlock'
import FeaturedEntries from './flexContent/featuredEntries'
import TeamMembers from './flexContent/teamMembers'
import ClientsSection from './flexContent/clientsSection'
import FocusAreas from './flexContent/focusAreas'
import TypeHero from './flexContent/typeHero'
import Newsletter from './flexContent/newsletter'
import ServicesBlock from './flexContent/servicesBlock'
import WorkWithUsBlock from './flexContent/workWithUsBlock'
import WorkShowcase from './flexContent/workShowcase'
import Slideshow from './flexContent/slideshow'
import LeadCapture from './flexContent/leadCapture'
import LatestNews from './flexContent/latestNews'
import useIntersect from './useIntersect'

const returnBlock = (data, index, entry) => {
  const components = {
    Callout,
    MissionPlan,
    MultiColWithText,
    ImageGrid,
    Instagram,
    RichText,
    HeaderBG,
    ImageBlock,
    FeaturedEntries,
    ClientsSection,
    FocusAreas,
    TypeHero,
    Newsletter,
    ServicesBlock,
    WorkWithUsBlock,
    WorkShowcase,
    TeamMembers,
    Slideshow,
    LeadCapture,
    LatestNews
  }

  const Block = components[data.componentID]
  return <Block key={data.componentID + index} data={data} entry={entry} />
}

const ContentBuilder = props => {
  const data = props.data
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })
  return data
    ? (
      <>
        <div>{data.map((block, index) => returnBlock(block, index, ref, entry))}</div>
      </>
    )
    : (
      <>
      No content blocks.
      </>
    )
}

export default ContentBuilder
