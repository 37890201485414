import React from 'react'
import ImageVideo from '../components/ImageVideo'
import styled from '@emotion/styled'
import { breakpoints } from '../styles/variables'

const FocusAreasImageGrid = styled('div')`
  position: absolute;
  display: flex;
  align-items: start;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  .image_overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  &.active {
    opacity: 1;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: 0;
  }
`

const FocusAreasImages = ({ images, active, title, index }) => {
  return (
    <FocusAreasImageGrid className={active === index ? 'active' : ''} key={index}>
      <div className="image_overlay"/>
      {images.map(img => (
        <ImageVideo objectFit="cover" file={img} key={img.id} />
      ))}
    </FocusAreasImageGrid>
  )
}

export default FocusAreasImages
