import React, { useState, useEffect } from 'react'
import FocusAreasImages from '../focusAreasImages'
import styled from '@emotion/styled'
import { H2, LargeParagraph, H5 } from '../shared/typography'
import { Section, SectionTitle, GridContainer, GridItem } from '../shared/section'
import { colors, breakpoints } from '../../styles/variables'
import useIntersect from '../useIntersect'
import { Link } from 'gatsby'

const FocusAreaLink = styled(Link)`
  padding-top: 2em;
  padding-bottom: 0;
  padding-right: 0;
  margin-bottom: 2em;
  display: flex;
  color: white;
  text-decoration: none;
  span {
    flex: 0 1 auto;
    padding-right: 10px;
    background: transparent;
  }
  .variable_arrow {
    position: relative;
    flex: 1 1 auto;
    padding: 1em 2em 1em 4em;
    overflow: hidden;
    &:after {
      content:'';
      position: absolute;
      right: 3px;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: white;
      transform: translate(0, -50%);
    }
    &:before {
      content:'';
      position: absolute;
      right: 4px;
      top: 50%;
      width: 12px;
      height: 12px;
      background-color: transparent;
      border-top: 2px solid;
      border-right: 2px solid;
      border-radius: 1px;
      transform: translate(0, -50%) rotate(45deg);
    }
  }
  svg {
    display: none;
    width: unset;
    height: 16px;
  }
  &.active {
    svg {
      fill: ${ colors.purple };
      transition: inherit;
      transform: translate(-20px, -50%);
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    display: flex;
    position: absolute;
    bottom: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    span {
      flex: 1 1 100%;
      margin-bottom: 2em;
    }
    .variable_arrow {
      flex: 1 1 100%;
      overflow: unset;
    }
    svg {
      height: 16px;
    }
    &.active {
      display: flex;
      opacity: 1;
      pointer-events: all;
      svg {
        transform: translate(0px, -50%);
      }
    }
  }
`

const FocusAreasContainer = styled(GridContainer)`
  height: 100%;
  min-height: 80vh;
  overflow: hidden;
  align-items: stretch;
  flex: 1 1 100%;
  .vc {
    display: flex;
    align-items: center;
    padding: 4em 3em 4em 0em;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      padding: 32px 32px;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    min-height: 0;
  }
`

const FocusImageContainer = styled(GridItem)`
  position: relative;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    min-height: 50vh;
    height: 100%;
  }
`

const CategoryList = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  z-index: 999;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
  }
  > div:first-of-type {
    padding: 2em;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      padding: 0;
    }
  }
`

const ImageList = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

const Indicators = styled('div')`
  height: 24px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 24px;
  padding: 4px 0;
  display: none;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    display: block;
  }
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    margin-right: 16px;
    border: white 2px solid;
    transition: background-color 0.3s;
    &.active {
      background-color: ${ colors.purple };
    }
  }
`

const FocusAreas = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])

  const [activeNumber, setNumber] = useState(0)
  const [isActive] = useState(true)
  const cats = data.focusAreaCategories

  useEffect(() => {
    let interval = null
    if (isActive) {
      interval = setInterval(() => {
        if (activeNumber < cats.length - 1) {
          setNumber(activeNumber => activeNumber + 1)
        } else {
          setNumber(activeNumber => 0)
        }
      }, 3000)
    } else if (!isActive && activeNumber !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, activeNumber, cats.length])

  return (
    <Section
      ref={ref}
      entry={entry}
      bgcolor={data.backgroundColor ? colors[data.backgroundColor] : colors.neutral}
      color={data.backgroundColor !== 'neutral' ? colors.neutral : colors.text}
      className={(seen ? 'shown ' : 'unshown ') + ' focusAreas fullwidth'}
    >
      <SectionTitle
        vision={entry.intersectionRatio}
        color={data.backgroundColor !== 'neutral' ? colors.neutral : colors.text}
      >
        <div className="section_title__inner">
          <span>{data.title}</span>
        </div>
      </SectionTitle>
      <FocusAreasContainer>
        <GridItem span="5" className="vc">
          <div>
            <H2>{data.header}</H2>
            <LargeParagraph className="mb-0 mt-1">{data.description.description}</LargeParagraph>
          </div>
        </GridItem>
        <FocusImageContainer span="7">
          <ImageList>
            {data.focusAreaCategories.map((cat, index) => (
              <FocusAreasImages
                index={index}
                key={cat.id}
                title={cat.categoryTitle}
                images={cat.categoryImages}
                active={activeNumber}
              />
            ))}
          </ImageList>
          <CategoryList>
            <div>
              {data.focusAreaCategories.map((cat, index) => (
                <FocusAreaLink
                  key={cat.id}
                  onMouseOver={() => setNumber(index)}
                  className={index === activeNumber ? 'active' : ''}
                  color={colors.neutral}
                  to={cat.linkToPage.pageType === 'Case Study' ? ('/case-study/' + cat.linkToPage.slug) : ('/' + cat.linkToPage.slug)}
                >
                  <H5>{cat.categoryTitle}</H5>
                </FocusAreaLink>
              ))}
            </div>
            <Indicators>
              {data.focusAreaCategories.map((cat, index) => (
                <span
                  onMouseOver={() => setNumber(index)}
                  onClick={() => setNumber(index)}
                  className={index === activeNumber ? 'active' : ''}
                  key={index}
                ></span>
              ))}
            </Indicators>
          </CategoryList>
        </FocusImageContainer>
      </FocusAreasContainer>
    </Section>
  )
}

export default FocusAreas
