import React, { useState, useEffect } from 'react'
import ImageVideo from '../ImageVideo'
import { Section } from '../shared/section'
import styled from '@emotion/styled'
import { Grid } from '../shared/componentBase'
import { colors, breakpoints } from '../../styles/variables'
import useIntersect from '../useIntersect'

const ClientSection = styled(Section)`
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${ breakpoints.tablet }px) {
    // padding-right: 0px !important;
  }
`

const ClientsSection = ({ data }) => {
  const images = data.clientLogos

  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])

  return (
    <ClientSection
      fullwidth
      ref={ref}
      entry={entry}
      bgcolor={data.bgColor ? data.bgColor : colors.neutral}
      color={colors.text}
      className={seen ? 'shown fullwidth' : 'unshown fullwidth'}
      style={{ paddingTop: `${ data.bgColor ? '' : '0px' }` }}
    >
      <div className="w-full grid gap-2 grid-cols-2 grid-rows-4 grid-flow-row items-center justify-center
      md:grid-rows-2 md:auto-cols-fr md:grid-flow-col">
        {images.map((img, index) => {
          return (
            <div key={index} className={`text-center xl:text-left 
            ${ index > 11 ? 'hidden' : index > 7 ? 'hidden lg:block' : '' }`}>
              <ImageVideo objectFit="contain" file={img} key={img.id} className="w-40 max-w-full md:w-48 " />
            </div>
          )
        })
        }
      </div>
    </ClientSection>
  )
}

export default ClientsSection
