import React, { useState, useEffect } from 'react'
import { H3, H4, TextContainer } from '../shared/typography'
import ImageVideo from '../ImageVideo'
import styled from '@emotion/styled'
import { grid, spacing, breakpoints, colors } from '../../styles/variables'
import richTextParser from '../../richTextParser'
import {
  OuterSection,
  Section,
  SectionTitle,
  GridContainer,
  GridItem
} from '../shared/section'
import useIntersect from '../useIntersect'
import { ArrowLink } from '../shared/link'
import ArrowRight from '../svg/arrowRight'
import { ArrowButton } from '../shared/buttons'

const MPSection = styled(Section)`
  padding: 0;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: 0em;
  }
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: 0em;
  }
`

const MPInfo = styled('div')`
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: ${ spacing.lg }px ${ spacing.lg }px ${ spacing.lg }px ${ spacing.xl * 2 }px;
  @media screen and (max-width: ${ breakpoints.desktop + 300 }px) {
    padding: ${ spacing.md }px;
    padding-left: ${ spacing.md + 16 }px;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: ${ spacing.md }px;
  }
`
const MPGridContainer = styled(GridContainer)`
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
  grid-auto-rows: min-content;
  .gatsby-image-wrapper {
    height: 100%;
    flex: 1 1 100%;
  }
  @media screen and (max-width: ${ breakpoints.tablet + 240 }px) {
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-gap: 0;
    .gatsby-image-wrapper {
      height: 50vh;
    }
    > div:first-of-type {
      order: 1;
    }
  }
`

const MissionPlan = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray(),
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])

  return (
    <OuterSection style={{ position: 'relative' }}>
      <MPSection
        ref={ref}
        entry={entry}
        bgcolor="#108579"
        color={colors.neutral}
        className={seen ? 'shown fullwidth' : 'unshown fullwidth'}
        style={{ zIndex: 1 }}
      >
        <SectionTitle vision={entry.intersectionRatio}>
          <div className="section_title__inner">
            <span>{data.sidebar}</span>
          </div>
        </SectionTitle>
        <MPGridContainer>
          <GridItem span="5">
            <MPInfo>
              <H3>{data.title}</H3>
              <H4>{data.subtitle}</H4>
              <TextContainer ta="left">
                {richTextParser(data.description)}
              </TextContainer>

              <ArrowButton bgcolor={'#108579'} color={colors.neutral} to={data.buttonLink}>
                <span>{data.buttonText}</span>
                <ArrowRight color={colors.neutral} style={{ top: 0 }}/>
              </ArrowButton>

            </MPInfo>
          </GridItem>
          <GridItem span="7">
            <ImageVideo file={data.image} />
          </GridItem>
        </MPGridContainer>
      </MPSection>
    </OuterSection>
  )
}

export default MissionPlan
