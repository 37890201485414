import styled from '@emotion/styled'
import { colors, grid, spacing, breakpoints } from '../../styles/variables'
import { shouldForwardList } from '../../utils'
/* eslint-disable max-len */
export const Component = styled('div')`
  width: 100%;
  color: ${ props => (props.color ? props.color : colors.text) };
  background-color: ${ props => (props.bgcolor ? props.bgcolor : colors.neutral) };
  margin: ${ props => (props.margin ? props.margin * 16 + 'px' : '0px') } auto;
  .gatsby-image-wrapper {
    transition: opacity 0.5s, transform 0.8s;
  }
  &.unshown {
    .gatsby-image-wrapper {
      opacity: 0;
      transform: translateY(8vh);
    }
  }
  &.shown {
    .gatsby-image-wrapper {
      opacity: 1;
      transform: translateY(0vh);
    }
  }
`

export const Grid = styled('div')`
  display: grid;
  justify-content: center;
  align-items: start;
  max-width: ${ props => (props.fw ? 'none' : `${ grid.width }px`) };
  margin: auto;
`

export const GridCustom = styled('div')`
  display: flex;
  flex-direction: ${ props => props.direction };
  justify-content: ${ props => props.justify };
  align-items: ${ props => props.align };
  text-align: ${ props => props.tc && 'center' };
  max-width: ${ props => (props.fw ? 'none' : `${ grid.width }px`) };
  margin: auto;
  padding: ${ spacing.xl }px;
`

export const FlexImageGrid = styled('div', {
  shouldForwardProp: prop => shouldForwardList(prop)
})`
  display: grid;
  grid-template-columns: ${ props =>
    props.cols ? `repeat(${ props.cols }, 1fr)` : 'repeat(auto-fit, minmax(300px, 1fr))' };
  grid-auto-rows: 1fr;
  grid-auto-flow: dense;
  grid-gap: ${ spacing.md }px;
  justify-content: ${ props => props.justify };
  align-items: stretch;
  max-width: ${ props => (props.fw ? 'none' : `${ grid.width }px`) };
  padding: ${ props => (props.fw && !props.gap ? '0' : `${ spacing.md }px`) };
  background-color: ${ props => (props.bgcolor ? props.bgcolor : '#ffffff') };
  margin: auto;
  @media screen and (max-width: ${ breakpoints.mobile }px) {
    grid-template-columns: 1fr;
  }
  div:last-child {
    grid-column: span ${ props => (props.remainder === 1 ? '3' : props.remainder === 2 ? '2' : '1') };
  }
`

export const FlexImageBlock = styled('div', {
  shouldForwardProp: prop => shouldForwardList(prop)
})`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: none;
  justify-content: ${ props => props.justify };
  align-items: ${ props => props.align };
  max-width: ${ props => (props.browser ? `${ grid.width * 0.6 }px` : props.fw ? 'unset' : `${ grid.width }px`) };
  padding: ${ props => (props.browser ? `${ spacing.md }px` : props.fw ? '0' : `${ spacing.xl }px ${ spacing.xl * 2 }px`) };
  background-color: ${ props => (props.bgcolor ? props.bgcolor : colors.neutral) };
  margin: auto;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: ${ props => (props.browser ? `${ spacing.md }px` : props.fw ? '0' : `${ spacing.lg }px ${ spacing.lg * 2 }px`) };
    min-height: 0;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: ${ props => (props.browser ? `${ spacing.md }px` : props.fw ? '0' : `${ spacing.lg }px ${ spacing.md }px`) };
    min-height: 0;
  }
  @media screen and (max-width: ${ breakpoints.mobile }px) {
    grid-template-columns: 1fr;
  }
  > div {
    box-shadow: ${ props => (props.browser ? '0px 0px 18px 0px rgba(0,0,24,0.1)' : 'none') };
    border-radius: ${ props => (props.browser ? '4px' : '0') };
    video {
      border-radius: ${ props => (props.browser ? '4px' : '0') };
    }
  }
`
