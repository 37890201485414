import React from 'react'
import { H2, LargeParagraph } from './shared/typography'
import ArrowRight from './svg/arrowRight'
import { ArrowButton } from './shared/buttons'
import { OuterSection, Section, GridContainer, GridItem } from './shared/section'
import { colors } from '../styles/variables'

const StaticWorkWithUsBlock = () => {
  return (
    <OuterSection bgcolor={colors.purple}>
      <Section className="fullwidth" bgcolor={colors.purple} color={colors.neutral}>
        <GridContainer>
          <GridItem span="4">
            <H2>Work with us.</H2>
          </GridItem>
          <GridItem span="1"></GridItem>
          <GridItem span="7">
            <LargeParagraph>Ready to change the world together? Same. </LargeParagraph>
            <ArrowButton bgcolor={colors.purple} color={colors.neutral} to={'/start-a-project'}>
              <span>Let's get started</span>
              <ArrowRight color={colors.neutral}/>
            </ArrowButton>
          </GridItem>
          <GridItem span="12">
          </GridItem>
        </GridContainer>
      </Section>
    </OuterSection>
  )
}

export default StaticWorkWithUsBlock
