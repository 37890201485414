import React, { useState, useEffect } from 'react'
import ImageVideo from '../ImageVideo'
import useIntersect from '../useIntersect'

const TeamMembers = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  useEffect(() => {
    if (entry.isIntersecting && seen === false) {
      setSeen(true)
    }
  }, [entry])

  return (
    <div
      ref={ref}
      entry={entry}
      className='px-site'
    >
      <div>
        <div className='md:gap-16 md:flex-row md:mb-16 md:items-center flex flex-col gap-4 mb-8'>
          <h2 className="h3">{data.sectionHeader}</h2>
          <p className="p">{data.sectionParagraph}</p>
        </div>
        <ul className='grid grid-cols-1 gap-8 md:grid-cols-[repeat(auto-fit,minmax(300px,1fr))]'>
          {data.people.map((person, i) => {
            const delay = (i * 100) + 'ms'
            const transitionDelay = {
              transitionDelay: delay
            }
            return (
              <li key={person.id} className={`${ seen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8' } transition-all duration-500 `} style={transitionDelay} >
                <div aria-hidden="true" className='mb-2'>
                  <ImageVideo className='aspect-square' objectPosition="50% 0%" file={person.headshot} />
                </div>
                <p>
                  <span className='h4 block mb-2'>{person.name}</span>
                  <span className='sr-only'>, </span>
                  <span className='h5 block'>{person.title}</span>
                </p>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default TeamMembers
