import React, { useState } from 'react'
import ImageVideo from '../ImageVideo'
import { H1, H2, TextContainer } from '../shared/typography'
import ArrowRight from '../svg/arrowRight'
import { Link } from 'gatsby'
import richTextParser from '../../richTextParser'
import { Section } from '../shared/section'
import { colors, radius, spacing, fonts, base, breakpoints } from '../../styles/variables'
import ReactRotatingText from 'react-rotating-text'
import { css } from '@emotion/react'

export const colorPulse = css`
@keyframes colorpulse {
    0% {
        color: ${ colors.purple };
    }
    20% {
        color: ${ colors.cobalt };
    }
    60% {
        color: ${ colors.green };
    }
    80% {
        color: ${ colors.orange };
    }
    100% {
        color: ${ colors.purple };
    }
}
animation: colorpulse infinite 6s;
animation-delay: 8s;
`

export const grid = css`
  display: flex;
  padding-top: 4rem;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    flex-direction: column;
  }
`

export const icon = css`
  width: 100%;
  max-width: 320px;
  margin-right: 4rem;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    max-width: 240px;
    margin-right: 0;
  }
`

export const buttonStyle = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 2rem;
  background-color: ${ colors.purple };
  color: ${ colors.text };
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 1.5 }px;
  line-height: ${ base.value * 2.75 }px;
  font-weight: 600;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: ${ colors.neutral };
  }
  svg {
    width: 60px;
    margin-left: 1rem;
  }
`

export const arrowStyle = css`
  background-color: ${ colors.purple };
`

export const spanStyle = css`
  background-color: ${ colors.purple };
`

export const subhead = css`
  p {
      font-size: 125%;
      font-weight: bold;
  }
`

const TypeHero = ({ data }) => {
  const [color, setColor] = useState(0)
  const colorArray = Object.values(colors)
  const setAndReset = function () {
    if (color < 8) {
      setColor(color + 1)
    } else {
      setColor(0)
    }
  }
  if (data.style === 'Static') {
    return (

      <div className={'mt-32 mb-8 px-8 md:px-24 lg:px-32 py-8 md:py-24 lg:py-24 text-text bg-neutral'}>

        <div className="flex flex-col md:flex-row items-center">
          {data.iconImage !== null && (
            <div className="w-full max-w-xs">
              <ImageVideo objectPosition="50% 0%" file={data.iconImage} />
            </div>
          )}
          <div className="space-y-8">
            {data.stationaryText !== null && (
              <h1 className="h2 leading-1 max-w-prose">
                {data.stationaryText}
              </h1>
            )}

            {data.subtext !== null
              ? (
                <TextContainer ta="left" css={subhead} >
                  {richTextParser(data.subtext)}
                </TextContainer>
              )
              : (
                ''
              )}
            {data.buttonLink !== null
              ? (
                <Link css={buttonStyle} to={data.buttonLink}>
                  <span css={spanStyle} >{data.buttonText}</span>
                  <ArrowRight color={colors.text}/>
                </Link>
              )
              : (
                ''
              )}
          </div>
        </div>
      </div>

    )
  } else {
    return (
      <Section
        fullpage
        color={colors.text}
        bgcolor={colors.neutral}
        className="!min-h-[80vh] md:!min-h-[95vh] fullwidth"
      >
        <div className="typehero__headline">
          <h1 className="h2 leading-1 max-w-prose !min-h-[5em] md:!min-h-[3.5em]">
            <span color={colors[color]}>{data.stationaryText}</span>
            <br />
            <span> </span>
            {data.changingText
              ? (
                <ReactRotatingText
                  onTypingStart={() => setAndReset()}
                  pause={2400}
                  typingInterval={40}
                  cursor={false}
                  items={data.changingText}
                  color={colorArray[color]}
                />
              )
              : (
                ' '
              )}
          </h1>
        </div>
        {data.subtext !== null
          ? (
            <TextContainer ta="left">
              {richTextParser(data.subtext)}
            </TextContainer>
          )
          : (
            ''
          )}
      </Section>
    )
  }
}

export default TypeHero
