import React from 'react'
import styled from '@emotion/styled'
import ImageVideo from '../ImageVideo'
import richTextParser from '../../richTextParser'
import { colors, breakpoints, spacing } from '../../styles/variables'
import { H3, H5, Paragraph } from '../shared/typography'
import { Section, SectionTitle, GridContainer, GridItem } from '../shared/section'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  prevArrow: <div style={{ width: '80px', marginRight: '-80px' }}><svg viewBox="0 0 89 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="89" y="90" width="89" height="90" transform="rotate(-180 89 90)" fill="white"/>
    <path d="M26.2929 43.2929C25.9024 43.6834 25.9024 44.3166 26.2929 44.7071L32.6569 51.0711C33.0474 51.4616 33.6805 51.4616 34.0711 51.0711C34.4616 50.6805 34.4616 50.0474 34.0711 49.6569L28.4142 44L34.0711 38.3431C34.4616 37.9526 34.4616 37.3195 34.0711 36.9289C33.6805 36.5384 33.0474 36.5384 32.6569 36.9289L26.2929 43.2929ZM61 43L27 43L27 45L61 45L61 43Z" fill="black"/>
  </svg>
  </div>,
  nextArrow: <div style={{ width: '80px', marginLeft: '-80px' }}><svg viewBox="0 0 89 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="89" height="90" fill="white"/>
    <path d="M62.7071 46.7071C63.0976 46.3166 63.0976 45.6834 62.7071 45.2929L56.3431 38.9289C55.9526 38.5384 55.3195 38.5384 54.9289 38.9289C54.5384 39.3195 54.5384 39.9526 54.9289 40.3431L60.5858 46L54.9289 51.6569C54.5384 52.0474 54.5384 52.6805 54.9289 53.0711C55.3195 53.4616 55.9526 53.4616 56.3431 53.0711L62.7071 46.7071ZM28 47L62 47L62 45L28 45L28 47Z" fill="black"/>
  </svg>
  </div>
}

const FeaturedSection = styled(Section)`
  padding: 0;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: 0em;
  }
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: 0em;
  }
`

const FeaturedGridContainer = styled(GridContainer)`
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
  grid-auto-rows: min-content;
  .gatsby-image-wrapper {
    height: 100%;
    flex: 1 1 100%;
  }
  @media screen and (max-width: ${ breakpoints.tablet + 240 }px) {
    .gatsby-image-wrapper {
      height: 70vh;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-gap: 0;
    .gatsby-image-wrapper {
      height: 50vh;
    }
    > div:first-of-type {
      order: 1;
    }
  }
`

const FeaturedGridItem = styled(GridItem)`
  height: calc(100vh - 60px);
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  
  &.__f_image {
    .gatsby-image-wrapper {
      height: 100%;
      flex: 1 1 100%;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet + 240 }px) {
    height: 70vh;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    &.__f_image {
      height: calc(50vh);
    }
    &.__f_info {
      height: auto;
    }
  }
`

const FeaturedInfo = styled('div')`
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: ${ spacing.lg }px ${ spacing.lg * 1.5 }px  ${ spacing.lg }px ${ spacing.xl * 1.25 }px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: ${ spacing.md }px;
    padding-left: ${ spacing.lg * 1.5 }px;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: ${ spacing.md }px;
  }
`

const Slideshow = ({ data, entry }) => {
  const makeContrast = function (featuredColor) {
    let textColor = colors.neutral
    const c = featuredColor ? featuredColor.substring(1) : 'f9dbbd' // strip #
    const rgb = parseInt(c, 16) // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff // extract red
    const g = (rgb >> 8) & 0xff // extract green
    const b = (rgb >> 0) & 0xff // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
    if (luma > 200) {
      textColor = colors.text
      return textColor
    }
    return textColor
  }
  return (
    <FeaturedSection className="fullwidth" entry={entry} fullpage>
      <SectionTitle vision={entry.intersectionRatio}>{data.sectionTitle}</SectionTitle>
      <Slide {...properties}>
        {data.slides.map(slide => (
          <FeaturedSection
            key={slide.headline + slide.id}
            fullpage
            className="fullwidth each-slide"
            bgcolor={slide.bgColor}>
            <FeaturedGridContainer>
              <FeaturedGridItem className="__f_info" span="5">
                <FeaturedInfo>
                  <div style={{ width: '100%', color: `${ slide.textColor }` }}>
                    {richTextParser(slide.slideText)}
                  </div>
                </FeaturedInfo>
              </FeaturedGridItem>
              <FeaturedGridItem className="__f_image" span="7">
                <ImageVideo file={slide.imageVideo} />
              </FeaturedGridItem>
            </FeaturedGridContainer>
          </FeaturedSection>
        ))}
      </Slide>
    </FeaturedSection>
  )
}

export default Slideshow
