import React, { useState } from 'react'
import { H4, HR, Paragraph } from './shared/typography'
import richTextParser from '../richTextParser'
import { ArrowLink } from './shared/link'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Plus from './svg/plus'
import { colors, breakpoints } from '../styles/variables'
import ArrowRight from './svg/arrowRight'

export const w100 = css`
  width: 100%;
`

export const ServiceModule = styled('div')`
  cursor: pointer;
  position: relative;
  padding: 52px 0 0;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: 32px 0 0;
  }
  h4 {
    transition: color 0.2s;
    margin-right: 40px;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      margin-right: 56px;
      margin-bottom: 16px;
    }
  }
  .header {
    position: relative;
  }
  .content {
    height: 0;
    overflow: hidden;
    margin: 0 0 16px 0;
    width: 100%;
    p {
      max-width: 800px;
    }
  }
  .plus {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    right: 0;
    transition: transform 0.2s;
    transform-origin: top;
    transform: rotate(0deg) translateY(-50%);
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      width: 40px;
      height: 40px;
    }
  }
  &:hover {
    h4 {
      color: ${ colors.purple };
    }
  }
  &.active {
    .plus {
      transform: rotate(45deg) translateY(-50%);
    }
    .content {
      height: 100%;
      margin-bottom: 60px;
    }
  }
  :focus-within {
    .plus {
      transform: rotate(45deg) translateY(-50%);
    }
    .content {
      height: 100%;
      margin-bottom: 60px;
    }
  }
`

export const ServicesLink = styled(ArrowLink)`
  display: inline-flex;
  overflow: visible;
  span {
    color: ${ colors.purple };
  }
  svg {
    right: -80px;
  }
  svg path {
    fill: ${ colors.purple };
  }
`

const Service = ({ data }) => {
  const [open, setOpen] = useState(false)
  function handleService () {
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }
  return (
    <div css={w100} onClick={() => handleService()}>
      <ServiceModule className={open ? 'active' : ''}>
        <div className="header">
          <H4>{data.serviceTitle}</H4>
          <Plus />
        </div>
        <div className="content">
          {data.serviceDescription && (
            <Paragraph>
              {richTextParser(data.serviceDescription)}
            </Paragraph>
          )}
          <ServicesLink tabIndex="1" bgcolor={colors.neutral} to="/start-a-project">
            <span>Let's Talk</span>
            <ArrowRight />
          </ServicesLink>
        </div>
      </ServiceModule>
      <HR />
    </div>
  )
}

export default Service
