import React from 'react'
import ImageVideo from '../components/ImageVideo'
import styled from '@emotion/styled'
import { Section, SectionTitle, GridContainer, GridItem } from '../components/shared/section'
import { H3, H5, Paragraph } from '../components/shared/typography'
import { spacing, colors, breakpoints } from '../styles/variables'
import SEO from '../components/seo'
import Helmet from 'react-helmet'

const CaseStudyHeader = ({ image, subhead, title, description, featuredColor }) => {
  if (featuredColor === null) {
    featuredColor = colors.neutral
  }
  let textColor = colors.text
  const c = featuredColor.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  if (luma < 40) {
    textColor = colors.neutral
  }
  return (
    <div className="md:mt-14 mt-16" style={{ backgroundColor: featuredColor }}>
      {image && image.file !== null
        ? (
          <SEO image={'https:' + image.file.url} />
        )
        : (
          <Helmet
            meta={[
              {
                property: 'og:video',
                content: 'https:' + image.file.url,
              }]
            }
          />
        )}
      <div className="w-full h-[66vh] md:h-[75vh]">
        <ImageVideo
          className="w-full h-full"
          imgClassName="h-full w-full"
          objectFit="cover" file={image} loading="eager"
        />
      </div>
      <Section bgcolor={featuredColor} color={textColor}>
        <SectionTitle>Case Study</SectionTitle>
        <div className="grid grid-cols-12 gap-4">
          <div className="md:col-span-5 col-span-12">
            <h1 className="h3 break-words">{title}</h1>
          </div>
          <div className="md:col-span-7 col-span-12 space-y-4">
            <h2 className="h5">{subhead}</h2>
            <p className="p">{description}</p>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default CaseStudyHeader
