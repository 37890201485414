import React, { useState, useEffect } from 'react'
import { TextContainer } from '../shared/typography'
import ImageVideo from '../ImageVideo'
import styled from '@emotion/styled'
import { grid, spacing, breakpoints } from '../../styles/variables'
import richTextParser from '../../richTextParser'
import { OuterSection, Section, SectionTitle } from '../shared/section'
import useIntersect from '../useIntersect'

const BgImage = styled('div')`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  .gatsby-image-wrapper {
    position: absolute !important;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
  }
`

const Callout = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])

  return (
    <OuterSection style={{ position: 'relative' }} bgcolor={data.bgColor && data.bgColor}>
      {data.backgroundImage &&
        <BgImage>
          <ImageVideo file={data.backgroundImage} />
        </BgImage>
      }
      <Section
        ref={ref}
        entry={entry}
        color={data.color && data.color}
        bgcolor={data.bgColor && data.bgColor}
        bgImage={data.backgroundImage && true}
        className={seen ? 'shown callout' : 'unshown callout'}
        style={{ zIndex: 1 }}
      >
        <SectionTitle vision={entry.intersectionRatio}>
          <div className="section_title__inner">
            <span>{data.title}</span>
          </div>
        </SectionTitle>
        <TextContainer ta="left">{richTextParser(data.calloutText)}</TextContainer>
      </Section>
    </OuterSection>
  )
}

export default Callout
