import { useStaticQuery, graphql } from 'gatsby'

export const useArchiveNews = () => {
  const { allContentfulNews } = useStaticQuery(
    graphql`
      query {
        allContentfulNews(sort: {fields: publishedDate, order: DESC}) {
            edges {
                node {
                    id
                    title
                    slug
                    category
                    description {
                      description
                    }
                    featuredImage {
                      gatsbyImageData
                    }
                    featuredColor
                    publishedDate(formatString: "MM.DD.YY")
                }
            }
        }
    }
    `
  )
  return allContentfulNews
}
