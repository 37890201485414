import React from 'react'
import { H2, LargeParagraph } from '../shared/typography'
import { OuterSection, Section, SectionTitle, GridContainer, GridItem } from '../shared/section'
import { colors } from '../../styles/variables'
import NewsletterForm from '../../components/newsletterForm'

const Newsletter = ({ data, entry }) => {
  return (
    <OuterSection bgcolor={colors.forest}>
      <Section className="fullwidth" entry={entry} bgcolor={colors.forest} color={colors.neutral}>
        <SectionTitle vision={entry.intersectionRatio}>{data.sectionTitle}</SectionTitle>
        <GridContainer>
          <GridItem span="4">
            <H2>{data.sectionHeader}</H2>
          </GridItem>
          <GridItem span="1"></GridItem>
          <GridItem span="7">
            <LargeParagraph>{data.sectionParagraph}</LargeParagraph>
          </GridItem>
          <GridItem span="12">
            <NewsletterForm/>
          </GridItem>
        </GridContainer>
      </Section>
    </OuterSection>
  )
}

export default Newsletter
