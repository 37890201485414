import React from 'react'
import ImageVideo from '../ImageVideo'
import { H3, H5 } from '../shared/typography'
import { WorkSection, SectionTitle, WorkGrid, WorkItem } from '../shared/section'
import { overlay } from '../shared/cardLink'
import { Link as GatsbyLink } from 'gatsby'

const WorkShowcase = ({ data, entry, ref }) => {
  const bigOnes = [1, 3, 7, 9, 13, 15, 19, 21, 25, 27, 31, 33, 37, 39, 43]
  const getGrid = index => {
    if (bigOnes.includes(index)) {
      return 'md:col-span-8 md:row-span-2'
    } else {
      return 'md:col-span-4 md:row-span-1'
    }
  }
  return (
    <WorkSection entry={entry} ref={ref} className="fullwidth">
      <SectionTitle>{data.sectionTitle}</SectionTitle>
      <div className="md:grid-cols-12 grid w-full h-full grid-flow-row-dense grid-cols-1 gap-4">
        {data.caseStudies.map((feat, index) => (
          <div key={index} className={`relative w-full h-full ${ getGrid(index) }`}>
            <GatsbyLink to={`/case-study/${ feat.slug }`} className="block w-full h-full group aspect-[5/4]">
              <div className='h-full'>
                <ImageVideo className="h-full" imgClassName="h-full" file={feat.featuredImg} />
                <div className="group-hover:opacity-100 absolute inset-0 z-50 flex flex-col items-center justify-center w-full h-full p-4 space-y-4 text-center bg-black opacity-0">
                  <h5 className="h5 text-white">{feat.title}</h5>
                  {/* {feat.topLineKPI && (
                    <div>{feat.topLineKPI}</div>
                  )} */}
                </div>
              </div>
            </GatsbyLink>
          </div>
        ))}
      </div>
    </WorkSection>
  )
}

export default WorkShowcase
