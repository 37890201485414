import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ImageVideo from './components/ImageVideo'
// eslint-disable-next-line max-len
import { H1, H2, H3, H4, H5, LargeParagraph, Blockquote, UnorderedList } from './components/shared/typography'
const Bold = ({ children }) => <span className="bold">{children}</span>
const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="p--small">{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <LargeParagraph>{children}</LargeParagraph>,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [BLOCKS.UL_LIST]: (node, children) => <UnorderedList>{children}</UnorderedList>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return (
        <div className="py-4">
          <ImageVideo objectFit="cover" file={node.data.target} />
        </div>
      )
    },
  },
}

const parseRichText = text => {
  return renderRichText(text, options)
  // return documentToReactComponents(text, options)
}

export default parseRichText
