import styled from '@emotion/styled'
import { spacing } from '../../styles/variables'
import { ArrowLink } from '../shared/link'
import { css } from '@emotion/react'

export const overlay = css`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
  transition: all 0.2s;
  a:hover & {
    background-color: rgba(0,0,0,1);
  }
`
export const overlay2 = css`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: all 0.2s;
  transform: scale(1.25);
  opacity: 0;
  a:hover & {
    transform: scale(1);
    opacity: 1;
  }
`

export const FeaturedSection = styled('div')`
  margin: ${ spacing.xl }px auto;
`

export const FeaturedLink = styled(ArrowLink)`
  color: white;
`
