import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import ImageVideo from '../ImageVideo'
import { Section, SectionTitle } from '../shared/section'
import { H2, TextContainer } from '../shared/typography'
import richTextParser from '../../richTextParser'
import { breakpoints, colors, spacing } from '../../styles/variables'
import useIntersect from '../useIntersect'

const MultiColGrid = styled('div')`
  display: grid;
  grid-template-columns: ${ props =>
    (props.cols === 2 ? 'repeat(2, minmax(0px, 1fr))' : 'repeat(3, minmax(0px, 1fr))') };
  grid-column-gap: 3rem;
  padding: 0em;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    padding: 0;
  }
`

const ColImage = styled('div')`
  max-width: 10rem;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    flex: 0 1 100px;
    margin-right: 32px;
  }
`

const SingleCol = props => (
  <div>
    {props.title || props.file
      ? (
        <div
          className={`
      flex gap-4 md:flex-col md:items-start items-center 
      ${ (props.index + 1) % 2 !== 0 ? 'flex-row' : 'flex-row-reverse' }`}>
          {props.file
            ? <div className="w-32">
              <ImageVideo loading="eager" file={props.file} />
            </div>
            : ''}
          {props.title ? <h4 className="h4 mb-0 md:mb-4" >{props.title}</h4> : ''}
        </div>
      )
      : ''}
    <div>{richTextParser(props.text)}</div>
  </div>
)

const MultiColWithText = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  const checkEntry = entry.intersectionRatio

  useEffect(() => {
    if (checkEntry > 0.2) {
      setSeen(true)
    }
  }, [checkEntry])
  return (
    <Section
      fullpage
      bgcolor={colors.neutral}
      color={colors.text}
      ref={ref}
      entry={entry}
      className={seen ? 'shown fullwidth ' : 'unshown fullwidth '}
    >
      <SectionTitle vision={entry.intersectionRatio}>
        <div>
          <span>{data.title}</span>
        </div>
      </SectionTitle>
      <div className="space-y-8 md:space-y-16">
        {data.header && <h2 className="h3">{data.header}</h2>}
        {data.imageCols &&
          <MultiColGrid cols={data.imageCols.length}>
            {data.imageCols.map((col, index) => (
              <SingleCol
                title={col.colTitle}
                text={col.columnTexts}
                index={index}
                key={col.colTitle + index}
                file={col.image ? col.image : ''}
              />
            ))}
          </MultiColGrid>
        }
      </div>
    </Section>
  )
}

export default MultiColWithText
