import React from 'react'
import Layout from '../components/layout'
import ContentBuilder from '../components/contentBuilder'
import CaseStudyHeader from '../components/caseStudyHeader'
import StaticWorkWithUsBlock from '../components/staticWorkWithUs'
import SEO from '../components/seo'
import Helmet from 'react-helmet'

const Template = props => {
  const { pageContext } = props
  return (
    <Layout
      header={pageContext.pageType === 'Case Study'}
      landingpage={pageContext.pageType !== 'Case Study' && pageContext.caseStudyCategory !== null}
      category={pageContext.caseStudyCategory}
      landingpagemenu={pageContext.pageType === 'Landing Page'}
    >
      <Helmet
        meta={[
          {
            name: 'google-site-verification',
            content:
              'detVviz_42UAcKi6TQaBs3ISWLUwh3E4_N9GlIAuVS4',
          }
        ]}
      />
      <SEO
        title={pageContext.title}
        description={pageContext.projectDescription ? pageContext.projectDescription.projectDescription : ' '}
      />
      {pageContext.pageType === 'Case Study'
        ? (
          <CaseStudyHeader
            image={pageContext.featuredImg}
            featuredColor={pageContext.featuredColor}
            title={pageContext.title}
            subhead={pageContext.subhead}
            description={pageContext.projectDescription ? pageContext.projectDescription.projectDescription : ' '}
          />
        )
        : (
          ''
        )}
      <ContentBuilder data={pageContext.contentBuilder} />
      {pageContext.pageType === 'Case Study' &&
        <StaticWorkWithUsBlock/>
      }
    </Layout>
  )
}

export default Template
