import React, { useState, useEffect } from 'react'
import ImageVideo from '../ImageVideo'
import { Component, FlexImageBlock } from '../shared/componentBase'
import useIntersect from '../useIntersect'

const ImageBlock = ({ data }) => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })

  const [seen, setSeen] = useState(false)

  useEffect(() => {
    if (entry.intersectionRatio > 0.2) {
      setSeen(true)
    }
  }, [entry])

  return (
    <Component
      bgcolor={data.bgColor && data.bgColor.toString()}
      ref={ref}
      entry={entry}
      className={seen ? 'shown callout' : 'unshown callout'}
    >
      <FlexImageBlock
        justify="center"
        align="center"
        fw={data.fullWidth && data.fullWidth.toString()}
        bgcolor={data.bgColor && data.bgColor.toString()}
        browser={data.browserImage && data.browserImage.toString()}
      >
        <ImageVideo file={data.image} imgClassName="w-full" />
      </FlexImageBlock>
    </Component>
  )
}

export default ImageBlock
