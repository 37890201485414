import React, { useState, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import ImageVideo from '../ImageVideo'
import { colors, breakpoints, spacing } from '../../styles/variables'
import { H3, H5, Paragraph } from '../shared/typography'
import { Section, SectionTitle, GridContainer, GridItem } from '../shared/section'
import { ArrowLink } from '../shared/link'
import ArrowRight from '../svg/arrowRight'
import { Link as GatsbyLink } from 'gatsby'
import { useArchiveNews } from '../../hooks/use-archives-news'

const LatestNews = ({ data }) => {
  const PageSize = 5
  const [currentPage, setCurrentPage] = useState(1)
  const { edges } = useArchiveNews()
  const posts = data.entryLink || edges
  const totalPosts = posts.length
  const totalPages = Math.ceil(posts.length / PageSize)
  const enablePagination = posts.length > PageSize

  const next = useRef(null)
  const prev = useRef(null)
  const goPrev = () => {
    setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)
  }
  const goNext = () => {
    setCurrentPage(currentPage === totalPages ? currentPage : currentPage + 1)
  }

  const currentPosts = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return posts.slice(firstPageIndex, lastPageIndex)
  }, [currentPage])

  // if (enablePagination) {
  //   const firstPageIndex = (currentPage - 1) * postsPerPage
  //   const lastPageIndex = firstPageIndex + postsPerPage
  //   posts = posts.slice(firstPageIndex, lastPageIndex)
  // }
  return (
    <div className="px-site my-20">
      <h2 className="h3 mb-8">{data.sectionTitle}</h2>
      <ul className="border-t border-black border-solid">
        {currentPosts.map(post => {
          const entry = post.node || post
          return (
            <li
              key={entry.title + entry.id}
              className="border-b border-black border-solid">
              <GatsbyLink to={'/news/' + entry.slug} className="hover:text-purple focus:text-purple block py-5 md:py-8 grid grid-cols-[min-content,1fr] md:grid-cols-[10rem,minmax(0px,1fr),10rem] gap-y-2 gap-x-4">
                <span className="h5">{entry.category}</span>
                <h3 className="h5 overflow-ellipsis md:whitespace-nowrap col-span-full md:col-span-1 md:order-1 order-2 overflow-hidden">{entry.title}</h3>
                <span className="tabular-nums md:order-2 md:text-right order-1 text-sm text-left">{entry.publishedDate}</span>
              </GatsbyLink>
            </li>
          )
        }
        )}
      </ul>
      {enablePagination && (
        <div className="mt-8 space-x-8">
          <button ref={prev} onClick={goPrev} disabled={currentPage === 1} type="button" className={`py-4 ${ currentPage === 1 && 'opacity-30' } `}>
            <span className="sr-only">Previous</span>
            <span className="block w-16 h-full origin-center rotate-180"><ArrowRight color="black" aria-hidden="true" /></span>
          </button>
          <button ref={next} onClick={goNext} disabled={currentPage === totalPages} type="button" className={`py-4 ${ currentPage === totalPages && 'opacity-30' } `}>
            <span className="sr-only">Next</span>
            <span className="block w-16 h-full"><ArrowRight color="black" aria-hidden="true" /></span>
          </button>
        </div>
      )}
    </div>
  )
}

export default LatestNews
