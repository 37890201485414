import React, { useState } from 'react'
import { validateEmail } from '../utils'
import styled from '@emotion/styled'
import { colors, breakpoints, fonts } from '../styles/variables'
import ArrowRight from './svg/arrowRight'
import { H5, TextContainer } from './shared/typography'

const Form = styled('form')`
  display: grid;
  grid-template-columns: 2fr 3fr 100px;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-template-columns: 1fr;
  }
  input {
    font-size: 1.25rem;
    font-family: ${ fonts.poppins };
    border-radius: 0;
    width: 100%;
  }
  input[type='text'],
  input[type='email'] {
    background: none;
    border: none;
    border-bottom: 1px solid ${ colors.neutral };
    color: ${ colors.neutral };
    text-align: center;
    padding: 20px 0;
    box-shadow: none;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      width: calc(100%);
      margin-right: 0px;
      padding: 20px 0;
      text-align: left;
      grid-column: 1 / span 1;
    }
    ::placeholder {
      text-transform: uppercase;
      letter-spacing: 0.4em;
      font-size: 14px;
      color: #c4c4c4;
      @media screen and (max-width: ${ breakpoints.tablet }px) {
        font-size: 12px;
      }
    }
    &:not(output):-moz-ui-:invalid {
      box-shadow: none;
    }
    &:invalid {
      box-shadow: none;
    }
    &.invalid {
      border-bottom-color: ${ colors.orange };
    }
  }
  input[type='email'] {
    width: 100%;
  }
  input[type='text'] {
    width: 100%;
    margin-right: 10px;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      grid-column: 1 / span 2;
      margin-right: 0px;
    }
  }
  button[type='submit'] {
    width: 60px;
    overflow: hidden;
    position: relative;
    background: none;
    border: none;
    border-bottom: 1px solid ${ colors.neutral };
    cursor: pointer;
    span {
      visibility: hidden;
    }
    svg {
      transition: opacity 0.2s;
      right: 0;
      position: absolute;
      fill: ${ colors.purple };
    }
    &.disabled {
      cursor: no-drop;
      svg {
        fill: white;
      }
    }
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      width: calc(100%);
      margin-right: 0px;
      padding: 40px 0 20px;
      grid-column: 1 / span 1;
      border-bottom: none;
      svg {
        width: 60px;
        right: unset;
        left: 0;
      }
    }
  }
`

const NewsletterForm = () => {
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState('')
  const [errorMsg, seterrorMsg] = useState(null)

  const validEmail = validateEmail(email)

  const onSubscribeClick = async e => {
    e.preventDefault()
    try {
      const response = await fetch('/.netlify/functions/subscribe', {
        method: 'post',
        body: JSON.stringify({
          email: email,
          merge: {
            FNAME: firstName
          }
        })
      })

      const body = await response.json()
      console.log(body)
      if (response.status === 500) {
        seterrorMsg(body.msg)
      } else {
        seterrorMsg(null)
        setSubscribed(true)
        setFirstName('')
        setEmail('')
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <>
      {errorMsg}
      {!subscribed
        ? (
          <Form onSubmit={onSubscribeClick}>
            <input
              aria-label="Newsletter Form"
              type="text"
              name="name"
              placeholder="First Name"
              onChange={e => setFirstName(e.target.value)}
              value={firstName}
            />

            <input
              aria-label="Newsletter Form"
              className={!validEmail && email.length > 1 ? 'invalid' : ''}
              type="email"
              name="userEmail"
              placeholder="email@company.com"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />

            <button title="Subscribe" type="submit" disabled={!validEmail} className={!validEmail ? 'disabled' : ''}>
              <span>Subscribe</span>
              <ArrowRight />
            </button>
            {errorMsg ? <p className="error-text">{errorMsg}</p> : ''}
          </Form>
        )
        : (
          <TextContainer ta="center">
            <H5>Thanks for subscribing. Talk soon!</H5>
          </TextContainer>
        )}
    </>
  )
}

export default NewsletterForm
