import React from 'react'
import SEO from '../components/seo'
import LeadForm from '../components/leadForm'
import Plus from '../components/svg/plus'
import Eye from '../images/eye-icon.png'

const StartForm = ({ introText, modal }) => (
  <div className="min-h-screen px-8 md:px-24 lg:px-32 py-8 md:py-24 lg:py-24 relative">
    <SEO title="Start a Project" keywords={['start']} />
    <div className="h-full">
      <div className="flex flex-col md:justify-start md:items-center min-h-full bg-purple p-4 md:p-8">
        {modal &&
        <button aria-label="Go Back" onClick={() => { window && window.history.back() }}
          className='fixed top-2 right-2 p-2 transform rotate-45' >
          <Plus className="w-16" />
        </button>}

        <div className="absolute top-1/2 left-5 hidden md:block" aria-hidden="true">
          <div className="relative">
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 -rotate-90">
              <h5 className="h5 whitespace-nowrap">Get Started</h5>
            </div>
          </div>
        </div>

        <div className="absolute top-1/2 right-5 hidden md:block" aria-hidden="true">
          <div className="relative">
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 rotate-90">
              <h5 className="h5 whitespace-nowrap">Get Started</h5>
            </div>
          </div>
        </div>

        <div className="max-w-prose mx-auto p-4 md:p-8 mt-4 text-center">
          <img src={Eye} alt="Eye Icon" aria-hidden="true" className="w-48 mx-auto" width="298" height="314" />
          {(introText && introText.introText) && (
            <p className="font-sans font-bold text-lg leading-2">
              {introText.introText}
            </p>
          )}
        </div>
        <div className="max-w-3xl p-4 md:p-8 mt-4 bg-white"><LeadForm /></div>
      </div>
    </div>
  </div>
)

export default StartForm
